import 'bootstrap';
import 'slick-carousel';

$(document).ready(function(){

    /* ------------------------------------------------------------------------ */
    /* header homepage animation */
    /* ------------------------------------------------------------------------ */ 

setTimeout(function(){
  $('.test1').addClass('showed');
}, 0);
setTimeout(function(){
  $('.test2').addClass('showed');
}, 300);
setTimeout(function(){
  $('.test3').addClass('showed');
}, 600);
setTimeout(function(){
  $('.test4').addClass('showed');
}, 900);
setTimeout(function(){
  $('.test5').addClass('showed');
}, 1200);
setTimeout(function(){
  $('.test6').addClass('showed');
}, 1500);


setTimeout(function(){
  $('.content_header .title').addClass('showed');
}, 1800);

setTimeout(function(){
  $('.content_header .title').addClass('decoration');
}, 2000);

setTimeout(function(){
  $('.content_header.inner .title').addClass('showed');
}, 400);


    /* ------------------------------------------------------------------------ */
    /* SETUP MENU */
    /* ------------------------------------------------------------------------ */ 

if( 'ontouchstart' in window ){ var click = 'touchstart'; }
else { var click = 'click'; }

    /* ------------------------------------------------------------------------ */
    /* MENU OPEN RESPONSIVE */
    /* ------------------------------------------------------------------------ */ 

$('div.burger').on(click, function(){
    if( !$(this).hasClass('open') ){ openMenu(); } 
    else { closeMenu(); }
}); 

function openMenu(){
    $('nav.navtop').addClass('animate');
    $('div.burger').addClass('open');
    $('div.burger-wrap').addClass('open');  
    $('body').css('overflow', 'hidden');
    
    $('.menu li').each(function(i){
        var item = $(this);
        setTimeout(function(){
            item.addClass('launch');
        }, 250*i);
    });

    setTimeout(function(){ 
        $('div.y').hide(); 
        $('div.x').addClass('rotate30'); 
        $('div.z').addClass('rotateneg'); 
    }, 70);     
}
    
function closeMenu(){
    $('.menu li').each(function(i){
        $(this).removeClass('launch');
    });

    setTimeout(function(){          
        $('div.burger-wrap').removeClass('open');
        $('div.burger').removeClass('open');                
        $('nav.navtop').removeClass('animate');
        $('body').css('overflow', 'initial');
        
        setTimeout(function(){          
            $('div.x').removeClass('rotate30'); 
            $('div.z').removeClass('rotateneg');            
        }, 50);
        setTimeout(function(){
            $('div.y').show();
        }, 70);
    }, 100);                                                        
}

    /* ------------------------------------------------------------------------ */
    /* SCROLL ANCRE */
    /* ------------------------------------------------------------------------ */ 

$('a[href^="#"]').click(function(){
    var the_id = $(this).attr("href");
    if (the_id === '#') {
        return;
    }
    $('html, body').animate({
        scrollTop:$(the_id).offset().top
    }, 'slow');
    return false;
});


}); //end jquery


$(function() {
    if($('#gallery').length){
        //FILTER + PAGINATION
        let filterItems = new List('gallery', {
            valueNames: $('.is-filtered').data('filters'),
            listClass: 'is-filtered',
            pagination: true,
            page: 6
        });

        $('#gallery .tri-btn').click(function() {
            let filter = $(this).data('filter');
        
            $('.tri li button').removeClass('btn-primary').addClass('btn-outline-primary');
            $(this).removeClass('btn-outline-primary').addClass('btn-primary');
            
            filterItems.filter(function (item) {
                if (item.elm.dataset.group === filter || filter === 'all') {
                    return true;
                }
                return false;
            });

            return false;
        });

        $('.pagination-section').append('<div class="next"> <button><i class="icomoon icon-arrow-right"></i></button> </div>');
        $('.pagination-section').prepend('<div class="prev"> <button><i class="icomoon icon-arrow-left"></i></button> </div>');

        $('.next').on('click', function(){
            $('.pagination .active').next().trigger('click');
        })
        $('.prev').on('click', function(){
            $('.pagination .active').prev().trigger('click');
        })
    }

    if($('#map').length){
        //MAP
        var mymap = L.map('map',{
            center: [44.3634522,4.7113851],
            zoom: 13
        })

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
            maxZoom: 18,
            attribution: '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
              'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            id: 'mapbox/streets-v11'
        }).addTo(mymap);

        var Icon = L.icon({
            iconUrl: '../public/assets/images/map.png',
            iconSize:     [37, 45],
            iconAnchor:   [0, 45],
            popupAnchor:  [18, -45]
        });

        L.marker([44.3634522,4.7113851], {icon: Icon}).addTo(mymap).bindPopup("<p><strong>MECAVIBRE SAS</strong></p><p>11 rue Paul Sabatier - ZI de Faveyrolles<br>26700 Pierrelatte</p>");
    }
});